import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'components/common'
import logo from 'assets/icons/logo.png'
import { Wrapper, Flex, Links, Details } from './styles'

export const Footer = () => (
	<Wrapper>
		<Flex as={Container}>
			<Links className="row">
				<div className="column">
					<h6>Services</h6>
					<Link to="">Digital Marketing</Link> 
					<br />
					<Link to="">eCommerce Strategy</Link>
					<br />
					<Link to="">Web Development</Link>
					<br />
					<Link to="">Influencer Marketing</Link>
				</div>
				<div className="column">
					<h6>Resources</h6>
					<Link to="">Case Studies</Link> 
					<br />
					<Link to="">Our Workshops</Link>
					<br />
					<Link href="https://www.angel.co/company/endslate" target="_blank" rel="noopener noreferrer nofollow">
					Join The Team
					</Link>
				</div>
				<div className="column">
					<h6>Follow Us</h6>
					<a href="https://www.instagram.com/endslate.co" target="_blank" rel="noopener noreferrer nofollow">
						Instagram
					</a>
					<br />
					<a href="https://www.facebook.com/endslate.co" target="_blank" rel="noopener noreferrer nofollow">
						Facebook
					</a>
					<br />
					<a href="https://www.linkedin.com/company/endslate" target="_blank" rel="noopener noreferrer nofollow">
						Linkedin
					</a>
					<br />
					<a href="https://www.twitter.com/endslate" target="_blank" rel="noopener noreferrer nofollow">
						Twitter
					</a>

				</div>
				<div className="column">
					<h6>Company</h6>
					<Link to="/about">Our Team</Link>
					<br />
					<Link to="/contact">Contact Us</Link>
					<br />
					<Link to="/privacy-policy">Privacy Policy</Link>
					<br />
					<Link to="/service-terms">Terms of Service</Link>
				</div>
			</Links>
		</Flex>
		<Details>
			<div className="copyright">
				<Link to="/">
					<img
						src={logo} 
						alt='endslate - full service marketing logo' 
					/>
					<a style={{fontSize:'28px', paddingTop:'5px', paddingLeft:'5px', fontWeight:'100', verticalAlign:'top', color:'#fff'}}>endslate</a>
				</Link>
				<p>
				Endslate, Inc. © All rights are reserved 2019 - {new Date().getFullYear()} | Made with{' '}
					<span aria-label="love" role="img">
					💖
					</span>{' '}
				in{' '}
					<a>
				Los Angeles, CA
					</a>
				</p>
			</div>
		</Details>
	</Wrapper>
)
