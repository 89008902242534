module.exports = {
	defaultTitle: 'endslate - Digital Marketing Agency',
	logo: 'favicon.ico',
	author: 'Jonathan Guzman, CEO @ Endslate, Inc.',
	url: 'https://enslate.co',
	legalName: 'Endslate, Inc.',
	defaultDescription: 'Endslate is a full-service digital marketing agency. Driving our clients growth through online marketing and full-stack eCommerce solutions.',
	socialLinks: {
		twitter: 'http://www.twitter.com/endslateco',
		github: 'https://github.com/endslate',
		linkedin: 'https://www.linkedin.com/company/endslate/',
		instagram: 'https://instagram.com/endslate.co',
		youtube: 'https://www.youtube.com/user/endslate',
	},
	googleAnalytics: 'UA-156532251-1',
	googleOptimize: 'OPT-PSBDSVG',
	googleAdwords: 'W-701846980',
	facebookPixel: '736913913518504',
	themeColor: '#47d6cf',
	backgroundColor: '#12d0a4',
	social: {
		facebook: 'appId',
		twitter: '@endslateco',
	},
	address: {
		city: 'Long Beach',
		region: 'California',
		country: 'United States',
		zipCode: '90802',
	},
	contact: {
		email: 'contact@endslate.co',
		phone: '(424)240-5332',
	},
	foundingDate: '2019',
	recaptcha_key: '6Le9L9UUAAAAAJMOo-exAmOVbrzEm4DiRIeTuHz1'
}
