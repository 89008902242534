import styled from 'styled-components'

export const Wrapper = styled.div`
	a {
		color: #6d6d6d;
		font-weight: 300;
		text-decoration: none;
		letter-spacing: 1px;
	}
	a.CTA {
		color: #ffffff;
		letter-spacing: .95px;
	}

	.CTA {
		background-image: linear-gradient(#50ffb4, #00aadc);
		border: none;
		padding: 5px 15px;
		border-radius: 10px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		transition-duration: 0.4s;
		margin-left: 20px;
	}

	.CTA:hover {
		background-image: linear-gradient(to left, #00aadc, #50ffb4);
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	}

	${({ desktop }) =>
		desktop
			? `
			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 2rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
			: `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-top: 2rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`
