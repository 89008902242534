import styled from 'styled-components'

export const Wrapper = styled.div`
	background-color: #000;

	@media (max-width: 1960px) {
		padding: 4rem 0 4rem;
	};
	h4 {
		color: #ffffff;
	};
	h6 {
		color: #ffffff;
		font-size: 18px;
		padding-top: 20px;
	};
	p {
		color: #ffffff;
	};
	a {
		color: #ffffff;
	};
	a:link {
  		color: #000;
		text-decoration: none;
	};
	a:visited {
		color: #000;
		text-decoration: none;
	}
`

export const Flex = styled.div`
	justify-content: space-between;

	@media (max-width: 680px) {
		flex-direction: column;
		text-align: left;
	}
`

export const Links = styled.div`
	a {
		text-decoration: none;
		color: #ffffff;
		line-height: 2rem;

		img {
			align-items: top;
			margin: 5px;
		}

		&:first-child,
		&:last-child {
			margin: 0;
		}
	};
	a:link {
  		color: #fff;
	};
	a:visited {
		color: #13b3ca;
	};
	.icons {
		width: 12px; 
		padding-right: 4px;
		color: #ffffff;
	};
	.column {
		float: left;
		width: 25%;
	};
	@media screen and (max-width: 600px) {
		.column {
			width: 50%;
		}
	};
	.row:after {
		content: "";
		display: table;
		clear: both;
	}
`

export const Details = styled.div`
	@media (max-width: 680px) {
		margin-bottom: 1rem;
	};
	.copyright {
		margin-top: 50px;
		margin-left: 5%;
		margin-right: 5%;
		text-align: center;
	};
	img {
		padding-right: 5px;
		vertical-align: middle;
		width: 2.5rem;
	};
	a {
		color: #fff;
	}
`
