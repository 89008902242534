import styled from 'styled-components'

export const Wrapper = styled.div`
	padding: 1.75rem 0;
	display: flex;
	align-items: middle;
	justify-content: space-between;

	img {
		padding-right: 8px;
		vertical-align: top;
		width: 2.5rem;
	}

	a {
		color: #212121;
		text-decoration: none;
	};
	a:link {
  		color: #000;
		text-decoration: none;

	};
	a:visited {
		color: #000;
		text-decoration: none;
	}
`
