import styled from 'styled-components'

export const Button = styled.button`
	border-radius: 4px;
	padding: .5rem 1.2rem;
	border: none;
	color: #fff;
	background-image: linear-gradient(to right, #47d6cf 0%, #12d0a4 25%, #13b3ca 100%);
	font-weight: 600;
	font-size: 20px;

	&:focus {
		outline: none;
	}

	&:disabled {
		background: gray;
	}

	${({ secondary }) =>
		secondary &&
		`
		background-image: linear-gradient(to right, #13b3ca 0%, #12d0a4 51%, #47d6cf 100%);
	`}
`
