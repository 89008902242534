import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'components/common'
import logo from 'assets/icons/logo.png'
import NavbarLinks from '../NavbarLinks'
import { Wrapper } from './styles'

const Navbar = () => (
	<Wrapper as={Container}> 
		<div>
			<Link to="/">
				<div style={{fontSize:'28px', marginTop:'0px', paddingLeft:'5px', fontWeight:'100'}}>
					<img
						src={logo}
						alt='endslate - full service marketing logo' 
					/>
					endslate
				</div>
			</Link>
		</div>	
		<NavbarLinks desktop />
	</Wrapper>
)

export default Navbar
